














































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab3FinalQuestions',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: null,
        part2: null,
        part3: null,
        part4: null,
        part5: null,
        part6: null,
        language: 'en',
      },
      questions: [
        {
          en: '1) In one step, ammonium chloride ($\\ce{NH4Cl}$) was added to lower the blood pH. The ammonium ion is what acts as the acid. The chloride ion does not have any acid/base properties. a) Why is the ammonium ion used as the acid source instead of $\\ce{HCl}$? b) Why is $\\ce{NH4Cl}$ used instead of some other ammonium compound (such as $\\ce{NH4NO3}$ or $\\ce{NH4I}$)?',
          fr:
            '1) Dans une étape, le chlorure d’ammonium (NH4Cl) était ajouté afin de diminuer le pH du sang.  L’ion ammonium joue le rôle de l’acide.  L’ion chlorure n’a aucune propriété acide/base.\n' +
            'a) Pourquoi l’ion ammonium est-il utilisé comme acide au lieu de $\\ce{HCl}$?\n' +
            "b) Pourquoi avez-vous utilisé $\\ce{NH4Cl}$ au lieu d'un autre composé d'ammonium (tel que $\\ce{NH4NO3}$ ou $\\ce{NH4I}$)?",
          image: '',
          inputName: 'part1',
        },
        {
          en: '2) What observation did you make each time an acidic substance was added to the beaker? Write a general reaction OR use equilibrium arguments to explain this.',
          fr: '2) Quelle observation avez-vous faites chaque fois qu’un acide était ajouté au bécher? Écrivez une réaction générale OU utilisez le concept d’équilibre afin d’expliquer vos observations.',
          image: '',
          inputName: 'part2',
        },
        {
          en: '3) The ability of hemoglobin (Hb) to carry oxygen throughout the body as oxyhemoglobin ($\\ce{HbO2}$) is dependent on the pH of the blood. What effect would acidosis have on the ability of a patient to transport oxygen? $\\ce{HbH+ + O2 -> HbO2 + H+}$',
          fr: '3) Hémoglobine (Hb) a la capacité de distribuer l’oxygène à travers le corps sous forme d’oxyhémoglobine ($\\ce{HbO2}$) mais cette capacité dépend du pH du sang. Quel effet aurait l’acidose sur la capacité de transporter l’oxygène? $\\ce{HbH+ + O2 -> HbO2 + H+}$',
          image: '',
          inputName: 'part3',
        },
        {
          en: '4) The solution pictured below was made by dissolving several drops of blood in some water. The solution on the right was made the same way except that a small amount of $\\ce{HCl}$ was also added to this tube. Based on your general knowledge about the color of blood and the information in question 3, propose an explanation for what happened.',
          fr: '4) La solution à gauche a été faite par la dissolution de plusieurs gouttes de sang dans l’eau.  La solution à droite a été faite de façon pareille, mais en ajoutant un peu de $\\ce{HCl}$ à l’éprouvette. À l’aide de vos connaissances générales de la couleur du sang et de la réponse à la question 3, faites une hypothèse afin d’expliquer la différence en couleur.',
          image: '/img/assignments/bloodwaterHCL.png',
          inputName: 'part4',
        },
        {
          en: '5) A fresh sample of soda had a pH of 2.92. The soda was placed on a magnetic stirrer and made to go flat. The pH is measured again. Should the pH of the flat soda be higher, lower, or the same as the pH of the fresh soda?',
          fr: '5) Un échantillon frais d’une boisson gazeuse a un pH de 2.92. La boisson a été mise sur un agitateur et agitée jusqu’à ce que la boisson n’ait plus de gaz.  Le pH a été mesuré de nouveau.  Le pH sera-t-il plus élevé, moins élevé ou pareil au pH de la boisson initiale? ',
          image: '',
          inputName: 'part5',
        },
        {
          en: '6) The bicarbonate/carbonic acid buffer is also present in chickens. However, chickens also combine the carbonate in their blood with calcium ions to make calcium carbonate for their eggshells. Since chickens do not sweat, they pant in hot weather. What effect would this have on the pH of their blood and the strength of the eggshells they produce?',
          fr: '6) Le tampon bicarbonate/acide carbonique est également présent dans le corps des poules.  Cependant, les poules mélangent l’ion carbonate dans le sang avec les ions calcium afin de produire le carbonate de calcium pour les coquilles d’œuf.  Comme les poules ne suent pas, elles halètent lorsqu’il fait chaud.  Quel effet aurait cette méthode de refroidissement sur le pH du sang des poules et sur la force des coquilles d’œuf produits?',
          image: '',
          inputName: 'part6',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
